import { render, staticRenderFns } from "./PresentationSlides.vue?vue&type=template&id=6f3e7faa&scoped=true&"
import script from "./PresentationSlides.vue?vue&type=script&lang=js&"
export * from "./PresentationSlides.vue?vue&type=script&lang=js&"
import style0 from "./PresentationSlides.vue?vue&type=style&index=0&id=6f3e7faa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f3e7faa",
  null
  
)

export default component.exports