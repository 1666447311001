<template>
  <div>
    <div>
      <div>
        <SelectBriefingLanguageComponent
          @handleChangeLanguage="(e) => (selectedLanguage = e)"
        />
      </div>
    </div>
    <div v-if="!loading">
      <v-btn
        color="primary"
        rounded
        @click="add_slide_dialog()"
        fab
        absolute
        top
        right
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-row
        v-if="!slides || slides.length == 0"
        justify="center"
        class="pa-4 mt-6"
      >
        <p>{{ $t("no_slides") }}</p>
      </v-row>
      <v-simple-table v-else>
        <thead>
          <tr>
            <th class="text-center">{{ $tc("slide", 1) }}</th>
            <th class="text-center">{{ $t("remove") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(slide_presentation, index) in slides" :key="index">
            <td>
              <v-img
                class="mx-auto my-4"
                :src="gs.get_photo_path(slide_presentation)"
                max-height="20rem"
                max-width="20rem"
              >
              </v-img>
            </td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1"
                @click="open_delete_dialog(slide_presentation)"
                color="red"
                min-width="0"
                small
              >
                <v-icon small>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-dialog v-if="dialog" v-model="dialog">
        <v-card>
          <v-card-title>
            <h4 class="dxa_modal_title h4">{{ $t("add_slide") }}</h4>
          </v-card-title>
          <v-card-text>
            <v-form @submit.stop.prevent="add_slide()" ref="form" class="mt-5">
              <div class="text-center">
                <div
                  class="slideImage mb-4 mx-auto"
                  color="secondary"
                  @click="openFilePicker"
                >
                  <v-img
                    style="cursor: pointer"
                    v-if="slide.Slide != null"
                    :src="slide.Slide"
                  ></v-img>
                  <v-avatar
                    v-else
                    color="secondary"
                    tile
                    height="15rem"
                    width="100%"
                    ><v-icon dark x-large>mdi-pencil</v-icon></v-avatar
                  >
                  <input
                    id="imageSlideInput"
                    name="imageSlideInput"
                    ref="imageSlideInput"
                    type="file"
                    accept="image/png, image/jpeg, image/bmp"
                    style="display: none"
                    @change="setImageSlide"
                  />
                </div>
              </div>
              <v-card-actions>
                <v-btn
                  class="dxa_modal_btnSuccess"
                  color="primary"
                  min-width="100"
                  :loading="dialog_loading"
                  type="submit"
                  >{{ $t("save") }}</v-btn
                >
                <v-btn
                  class="dxa_modal_btnError"
                  color="red"
                  min-width="100"
                  @click="dialog = false"
                  >{{ $t("cancel") }}</v-btn
                >
              </v-card-actions>
            </v-form>
            <v-alert class="ma-2" v-if="error" type="error">{{
              $t(error)
            }}</v-alert>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-if="delete_dialog" v-model="delete_dialog">
        <DeleteConfirmationModal
          :obj="delete_obj"
          :name="$t('this_slide')"
          @close="delete_dialog = false"
          @delete="remove_slide"
        ></DeleteConfirmationModal>
      </v-dialog>
    </div>
    <v-container v-else>
      <v-progress-circular
        style="margin-left: 50%"
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-container>
  </div>
</template>

<style lang="scss" scoped>
.slideImage {
  max-height: 20rem !important;
  max-width: 20rem !important;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import SelectBriefingLanguageComponent from "../components/SelectBriefingLanguageComponent";

export default {
  name: "ManageCompanyPresentation",

  components: {
    DeleteConfirmationModal,
    SelectBriefingLanguageComponent,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    dialog_loading: false,
    dialog: false,
    delete_dialog: false,
    delete_obj: null,
    company_id: null,
    error: null,
    slides: [],
    slide: {
      CompanyId: null,
      Slide: null,
      SlideName: null,
    },
    selectedLanguage: null,
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
  },
  props: {
    BriefingId: Number,
    company_prop: Object,
  },

  async created() {
    this.reload_presentation();
  },
  watch: {
    company_prop(val) {
      this.reload_presentation();
    },
    selectedLanguage() {
      this.reload_presentation();
    },
  },

  methods: {
    async reload_presentation() {
      this.loading = true;
      await this.apiService
        .getRequest(
          `companybriefing/presentation/${this.BriefingId}/${this.selectedLanguage}`
        )
        .then((resp) => {
          this.slides = JSON.parse(resp.message);
        });
      this.loading = false;
    },
    openFilePicker() {
      this.$refs.imageSlideInput.click();
    },
    setImageSlide(event) {
      var reader = new FileReader();
      var file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.slide.SlideName = file.name;
        this.slide.Slide = reader.result;
      };
    },
    open_delete_dialog: function (slide) {
      this.delete_obj = {
        CompanyId: this.company_prop.CompanyId,
        Slide: slide,
        BriefingId: this.BriefingId,
      };
      this.delete_dialog = true;
    },
    remove_slide: async function (slide) {
      this.dialog_loading = true;
      this.error = null;
      await this.apiService
        .deleteRequest("companybriefing/slide/delete/", slide)
        .then((result) => {
          var index = this.slides.indexOf(this.delete_obj.Slide);
          this.slides.splice(index, 1);
          this.delete_dialog = false;
        })
        .catch((error) => {
          this.$emit("error", error.body.message);
          this.error = error.body.message;
        });
      this.dialog_loading = false;
    },
    add_slide_dialog: function () {
      this.slide = {
        CompanyId: null,
        Slide: null,
        SlideName: null,
        BriefingId: this.BriefingId,
        Language: this.selectedLanguage,
      };
      this.dialog = true;
    },
    checkForm: function () {
      this.error = null;
      var result = this.$refs.form.validate();
      if (result) {
        this.add_slide();
      } else {
        this.dialog_loading = false;
      }
    },
    add_slide: async function () {
      if (this.dialog_loading) {
        return;
      }
      this.dialog_loading = true;
      this.slide.CompanyId = this.company_prop.CompanyId;
      await this.apiService
        .postRequest("companybriefing/slide/add", this.slide)
        .then((result) => {
          this.slides = JSON.parse(result.message);
          this.dialog = false;
        })
        .catch((error) => {
          this.error = error.body.message;
        });
      this.dialog_loading = false;
    },
  },
};
</script>
